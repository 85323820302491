import React, {FC} from "react";
import Lowlight from "react-lowlight";
import js from "highlight.js/lib/languages/javascript";

Lowlight.registerLanguage("javascript", js);

export interface CodeProps {
    language?: string;
    code: string;
}

const Code: FC<CodeProps> = ({language, code}) => {
    return code ? <Lowlight language={language} value={code} /> : null;
};

export default Code;
